<template>
    <Layout>
        <Nav />
        <Main>
            <!-- Person filter and Persons -->
            <section role="region" class="flex w-full  min-h-screen font-roboto">
                <section
                    role="region"
                    aria-labelledby="person list filters"
                    class="min-h-full w-1/4 px-6 py-7 border-r bg-white"
                >
                    <div aria-labelledby="filterHeader" class="flex justify-between items-center">
                        <div class="text-2xl">
                            {{ $t('persons.title') }}
                        </div>
                        <a class="text-sm text-primary-default" @click="clearFilters">{{ $t('persons.clearAll') }}</a>
                    </div>
                    <div class="flex flex-col gap-4 mt-8">
                        <div class="relative rounded-md h-12">
                            <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                <Icon
                                    class="h-4 w-4"
                                    aria-hidden="true"
                                    :icon="'search'"
                                />
                            </div>
                            <input
                                :placeholder="$t('persons.searchPerson')"
                                type="text"
                                name="search"
                                id="searchInput"
                                v-model="searchQuery"
                                @input="debouncedSearch"
                                class="block w-full h-full rounded-[100px] border border-light py-1.5 pl-10 placeholder:font-normal placeholder:text-light-gray sm:text-sm sm:leading-4"
                            />
                        </div>
                    </div>
                    <div class="mt-4" aria-labelledby="screening monitor">
                        <div class="flex items-center justify-between">
                            <label class="text-xs leading-3 font-semibold">{{ $t('persons.filters.type.screeningMonitor').toUpperCase() }}</label>
                            <a class="text-sm text-primary-default" @click="clearScreeningOption">{{ $t("persons.clear") }}</a>
                        </div>
                        <div class="w-full border border-grey-200"></div>
                        <div class="mt-2">
                            <InputCheckbox
                                :items="screeningOptions"
                                unique-id="unique-checkbox-id"
                                v-model="selectedScreeningOption"
                            />
                        </div>
                    </div>
                    <div class="mt-4" aria-labelledby="hit type">
                        <div class="flex items-center justify-between">
                            <label class="text-xs leading-3 font-semibold">{{ $t("persons.filters.type.screeningStatus").toUpperCase() }}</label>
                            <a class="text-sm text-primary-default" @click="clearCheckboxOption">{{ $t("persons.clear") }}</a>
                        </div>
                        <div class="w-full border border-grey-200"></div>
                        <div class="mt-2">
                            <InputCheckbox
                                v-model="selectedCheckboxOptions"
                                :items="checkboxOptions"
                                unique-id="unique-checkbox-id"
                            />
                        </div>
                    </div>
                </section>
                <section class="flex h-full w-3/4 pt-7 pr-7 pl-7 flex-col">
                    <div v-if="personsLoading" class="mt-32">
                        <div class="">
                            <LoadAnimationBar :height="90" />
                            <LoadAnimationBar :height="90" />
                            <LoadAnimationBar :height="90" />
                            <LoadAnimationBar :height="90" />
                            <LoadAnimationBar :height="90" />
                            <LoadAnimationBar :height="90" />
                            <LoadAnimationBar :height="90" />
                            <LoadAnimationBar :height="90" />
                            <LoadAnimationBar :height="90" />
                            <LoadAnimationBar :height="90" />
                        </div>
                    </div>
                    <div
                        v-if="!personsLoading"
                        role="region"
                        aria-labelledby="customers list"
                        class=""
                    >
                        <!-- Results and other content -->
                        <div class="w-full h-full">
                            <div class="flex items-center mb-5">
                                <span
                                    class="px-4 py-2 text-xl text-center flex font-medium rounded-full border border-light"
                                >{{
                                    persons.total }}</span>
                                <span class="text-xl font-medium ml-3">{{ resultText }}</span>
                            </div>
                            <div class="flex flex-col rounded-xl" v-if="persons.persons.length > 0">
                                <ul role="list" class="divide-y divide-light  border-light">
                                    <li
                                        v-for="(person, index) in persons.persons"
                                        :key="'person_' + index"
                                        class="overflow-hidden hover:shadow-lg"
                                        :class="{
                                            'rounded-t-lg': index === 0,
                                            'rounded-b-lg': index === persons.persons.length - 1,

                                        }"
                                    >

                                        <PersonCard
                                            @click="$router.push({ path: `/aml/persons/${person.id}` })"
                                            :details="getPersonData(person)"
                                        />
                                    </li>
                                </ul>
                            </div>
                            <div class="w-full mt-3">
                                <Pagination
                                    :items-count="persons.total"
                                    :items-per-page="itemsPerPage"
                                    :current-page="currentPage"
                                    @change-page="changePage"
                                    @next-page="nextPage"
                                    @prev-page="prevPage"
                                />
                            </div>
                        </div>

                    </div>
                </section>

            </section>
        </Main>
    </Layout>
</template>
<script lang="ts">
import Icon from "@/components/Icon.vue";
import Nav from "@/components/Nav.vue";
import Pagination from "@/components/Pagination.vue";
import PersonCard from "@/components/PersonCard.vue";
import { queryPersons } from "@/lib/persons/query_persons";
import { ScreeningMonitor } from "@/types/person_filter";
import { CheckboxItem } from "@/ui/inputs/input_helper";
import InputCheckbox from "@/ui/inputs/InputCheckBox.vue";
import debounce from "debounce";
import { defineComponent, ref } from "vue";
import Layout from "../components/Layout.vue";
import Main from "../components/Main.vue";
import LoadAnimationBar from "@/ui/LoadAnimationBar.vue";

export default defineComponent({
    name: "Persons",
    components: {
        Nav,
        Layout,
        Main,
        Icon,
        PersonCard,
        Pagination,
        InputCheckbox,
        LoadAnimationBar,
    },
    data() {
        return {
            personsLoading: true,
            persons: {
                total: 0,
                persons: [] as any[],
            },
            currentPage: 1,
            itemsPerPage: 10,
            searchQuery: "",
            selectedScreeningOption: ref<string[]>([]),
            selectedHitOption: "all",
            selectedCheckboxOptions: ref<string[]>([]),
            screeningOptions: [
                { text: this.$t("persons.filters.monitorOptions.on"), key: "on" },
                { text: this.$t("persons.filters.monitorOptions.off"), key: "off" },
            ] as CheckboxItem[],
            hitOptions: [
                { label: this.$t("persons.filters.hitOptions.showAll"), value: "all" },
                { label: this.$t("persons.filters.hitOptions.allHits"), value: "allHits" },
                { label: this.$t("persons.filters.hitOptions.allHits"), value: "new_hits" },
                { label: this.$t("persons.filters.hitOptions.noHit"), value: "no_hits" },
            ],
            checkboxOptions: [
                { text: this.$t("persons.filters.screenStatusOptions.newHits"), key: "new_hits" },
                { text: this.$t("persons.filters.screenStatusOptions.pep"), key: "pep" },
                { text: this.$t("persons.filters.screenStatusOptions.sanction"), key: "sanction" },
                { text: this.$t("persons.filters.screenStatusOptions.sip"), key: "sip" },
                { text: this.$t("persons.filters.screenStatusOptions.rca"), key: "rca" },
                { text: this.$t("persons.filters.screenStatusOptions.noHit"), key: "no_hits" },
            ] as CheckboxItem[],
        };
    },
    methods: {
        async fetchPersons() {
            this.personsLoading = true;
            const screeningOptions = Object.values(this.selectedScreeningOption);
            const screeningStatus = Object.values(this.selectedCheckboxOptions);
            try {
                const offset = this.currentPage - 1;
                const { total, persons } = await queryPersons(
                    offset,
                    this.itemsPerPage,
                    this.searchQuery,
                    screeningOptions,
                    screeningStatus,
                );

                this.persons.total = total;
                this.persons.persons = persons;

                if (total === 0) {
                    this.currentPage = 0;
                }

                this.updateUrlParams();
            } catch (err) {
                console.log(err);
            } finally {
                this.personsLoading = false;
            }
        },
        clearFilters() {
            this.searchQuery = "";
            this.selectedScreeningOption = [];
            this.selectedCheckboxOptions = [];
        },
        clearCheckboxOption() {
            this.selectedCheckboxOptions = [];
        },
        clearScreeningOption() {
            this.selectedScreeningOption = [];
        },
        handleSelectedScreeningOption(value: any) {
            this.selectedCheckboxOptions = value;
            this.fetchPersons();
        },
        updateUrlParams() {
            const query: any = {
                searchQuery: this.searchQuery,
                screeningMonitor: this.selectedScreeningOption,
                currentPage: this.currentPage,
            };
            this.$router.push({ query });
        },

        getPersonData(person: any) {
            return {
                ...person,
                ...person.data?.person_info,
            };
        },
        nextPage() {
            if (this.currentPage < Math.ceil(this.persons.total / this.itemsPerPage)) {
                this.currentPage++;
                this.fetchPersons();
            }
        },
        prevPage() {
            if (this.currentPage > 1) {
                this.currentPage--;
                this.fetchPersons();
            }
        },
        changePage(page: number) {
            this.currentPage = page;
            this.fetchPersons();
        },

        debouncedSearch: debounce(function (this: any) {
            (this.currentPage = 1), this.fetchPersons();
        }, 400),
        isScreeningMonitor(value: string): value is ScreeningMonitor {
            return Object.values(ScreeningMonitor).includes(value as ScreeningMonitor);
        },
    },
    computed: {
        resultText(): string {
            return this.$t("persons.resultsFound", this.persons.total);
        },
    },
    watch: {
        searchQuery() {
            this.debouncedSearch();
        },
        selectedScreeningOption() {
            this.currentPage = 1;
            this.fetchPersons();
        },
        selectedCheckboxOptions() {
            this.currentPage = 1;
            this.fetchPersons();
        },
    },
    async mounted() {
        const query = this.$route.query;
        this.searchQuery = typeof query.searchQuery === "string" ? query.searchQuery : "";
        this.currentPage = parseInt(query.currentPage as string) || 1;
        await this.fetchPersons();
    },
});
</script>
