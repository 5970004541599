import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex gap-3 items-center" }
const _hoisted_2 = { class: "m-0" }
const _hoisted_3 = { class: "mt-3 w-full flex gap-3" }
const _hoisted_4 = { class: "mt-3 w-full h-[300px] overflow-y-auto bg-gray-100 rounded-md text-sm" }
const _hoisted_5 = { class: "flex-1" }
const _hoisted_6 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputLabel = _resolveComponent("InputLabel")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_InputGroup = _resolveComponent("InputGroup")!
  const _component_InputTextLocalized = _resolveComponent("InputTextLocalized")!
  const _component_SwitchInput = _resolveComponent("SwitchInput")!
  const _component_InputDropdown = _resolveComponent("InputDropdown")!
  const _component_ButtonSingle = _resolveComponent("ButtonSingle")!
  const _component_PopupDialogSplit = _resolveComponent("PopupDialogSplit")!
  const _component_PopupDialog = _resolveComponent("PopupDialog")!

  return (_openBlock(), _createBlock(_component_PopupDialog, {
    "is-visible": true,
    header: _ctx.$t('settings.approvals.header'),
    icon: 'wand-magic-sparkles',
    "show-cancel-button": true,
    "show-action-button": true,
    "action-text": _ctx.$t('common.actions.save'),
    "action-icon": 'floppy-disk',
    "is-action-disabled": !_ctx.dialog.valid || !_ctx.modelChanged,
    progress: _ctx.dialog,
    onCloseModal: _ctx.onClose,
    onAction: _ctx.onSave
  }, {
    default: _withCtx(() => [
      _createVNode(_component_PopupDialogSplit, {
        "is-form": "",
        "prompt-text": _ctx.promptText,
        "prompt-hint": _ctx.promptHint,
        class: "overflow-y-auto pr-1"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_InputGroup, { width: 12 }, {
            default: _withCtx(() => [
              _createVNode(_component_InputLabel, {
                text: _ctx.$t('settings.approvals.keyLabel'),
                "unique-id": 'end-key',
                required: ""
              }, null, 8, ["text"]),
              _createVNode(_component_InputText, {
                modelValue: _ctx.model.key,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model.key) = $event)),
                "unique-id": 'end-key',
                disabled: _ctx.isEditMode,
                onInput: _ctx.validateDialog
              }, null, 8, ["modelValue", "disabled", "onInput"])
            ]),
            _: 1
          }),
          _createVNode(_component_InputGroup, { width: 12 }, {
            default: _withCtx(() => [
              _createVNode(_component_InputLabel, {
                text: _ctx.$t('settings.approvals.nameLabel'),
                "unique-id": 'end-name',
                required: ""
              }, null, 8, ["text"]),
              _createVNode(_component_InputTextLocalized, {
                modelValue: _ctx.model.name,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.model.name) = $event)),
                "unique-id": 'end-name',
                onInput: _ctx.validateDialog,
                onEnter: _ctx.onSave
              }, null, 8, ["modelValue", "onInput", "onEnter"])
            ]),
            _: 1
          }),
          _createVNode(_component_InputGroup, {
            class: "mt-3",
            width: 12
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createVNode(_component_SwitchInput, {
                  modelValue: _ctx.model.useApproversList,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.model.useApproversList) = $event))
                }, null, 8, ["modelValue"]),
                _createElementVNode("label", _hoisted_2, _toDisplayString(_ctx.$t('settings.approvals.useApproversListLabel')), 1)
              ])
            ]),
            _: 1
          }),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_InputDropdown, {
              modelValue: _ctx.selectedEmployee,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.selectedEmployee) = $event)),
              items: _ctx.employeesOptions,
              "max-height": 250,
              placeholder: _ctx.$t('settings.approvals.addApprover'),
              class: "flex-1"
            }, null, 8, ["modelValue", "items", "placeholder"]),
            _createVNode(_component_ButtonSingle, {
              text: _ctx.$t('common.actions.add'),
              outline: true,
              onClicked: _ctx.addApprover,
              disabled: _ctx.selectedEmployee == ''
            }, null, 8, ["text", "onClicked", "disabled"])
          ]),
          _createElementVNode("div", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.model.approvers, (approver, index) => {
              return (_openBlock(), _createElementBlock("div", {
                key: approver,
                class: _normalizeClass({'bg-gray-200': index % 2, 'flex items-center py-2 px-3': true})
              }, [
                _createElementVNode("div", _hoisted_5, _toDisplayString((_ctx.employees.find(x => x.uid == approver)?.givenName + ' ' + _ctx.employees.find(x => x.uid == approver)?.familyName) || approver), 1),
                _createElementVNode("div", {
                  onClick: ($event: any) => (_ctx.removeApprover(approver)),
                  class: "text-red-800 hover:text-red-900 cursor-pointer"
                }, _toDisplayString(_ctx.$t('common.actions.remove')), 9, _hoisted_6)
              ], 2))
            }), 128))
          ])
        ]),
        _: 1
      }, 8, ["prompt-text", "prompt-hint"])
    ]),
    _: 1
  }, 8, ["header", "action-text", "is-action-disabled", "progress", "onCloseModal", "onAction"]))
}