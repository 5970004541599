import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  "aria-describedby": "existing company selector",
  class: "bg-primary-background/30 rounded-lg p-4 space-3"
}
const _hoisted_2 = { class: "flex gap-3 items-center" }
const _hoisted_3 = { class: "space-2" }
const _hoisted_4 = { class: "mb-0 font-bold block" }
const _hoisted_5 = { class: "text-sm" }
const _hoisted_6 = { class: "grid grid-cols-2 gap-3 pt-3" }
const _hoisted_7 = ["onClick"]
const _hoisted_8 = { class: "block font-medium m-0 cursor-pointer text-sm" }
const _hoisted_9 = { class: "block text-gray-500 m-0 cursor-pointer text-xs" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Icon, {
        icon: "existing-process",
        class: "w-10 h-10 p-2 bg-primary-default rounded-full hidden lg:block"
      }),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("label", _hoisted_4, _toDisplayString(_ctx.$t('newCustomer.headerExistingCompany')), 1),
        _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.$t('newCustomer.existingCompanyInstruction')), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.projects, (company, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: index,
          class: "bg-white p-3 rounded-md cursor-pointer hover:outline hover:outline-2 outline-primary-default",
          onClick: ($event: any) => (_ctx.$router.push({ path: `/aml/customers/${company.id}/overview` }))
        }, [
          _createElementVNode("label", _hoisted_8, _toDisplayString(company.name), 1),
          _createElementVNode("label", _hoisted_9, _toDisplayString(_ctx.workflow(company.workflow)), 1)
        ], 8, _hoisted_7))
      }), 128))
    ])
  ]))
}