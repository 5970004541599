<template>
    <div
        class="group flex items-center min-h-[5.5rem] py-5 pr-5 pl-3 gap-2 box-border cursor-pointer bg-white hover:bg-background font-roboto"
    >
        <div class="h-full border-r border-background mr-2">
            <Icon class="w-12 h-12 sm:w-12 sm:h-12 rounded-full p-2" :icon="displayIcon" />
        </div>
        <div class="min-w-0 pl-1 sm:pl-2 w-full">
            <div class="flex items-center truncate">
                <div class="min-w-0 space-y-3">
                    <div
                        class="leading-5 mr-2 text-gray-700 text-base capitalize break-words overflow-hidden whitespace-normal"
                    >
                        {{ details?.name }}
                    </div>
                    <div
                        class="flex items-center gap-x-1 text-xs truncate min-w-0 leading-4 text-light-gray group-hover:text-gray-700 whitespace-nowrap"
                    >
                        <!-- <span>Created: {{ new Date(details?.createdAt).toLocaleDateString() }}</span> -->
                        <span>{{ assignName }}</span>
                        <span>{{ ' - ' }}</span>
                        <span>{{ workflow }}</span>
                    </div>
                </div>

                <div class="ml-auto">
                    <div class="flex items-center border border-light rounded-3xl text-sm overflow-hidden">
                        <div class="border-r border-light py-2 pl-3 pr-4 space-x-2 bg-white">
                            <span class="inline-block w-2 h-2 rounded-full" :class="[displayRiskStyle.style]"></span>
                            <span>{{ displayRiskStyle.text }}</span>
                        </div>
                        <div class="p-2 pr-5 pl-5" :style="{ backgroundColor: risk.color }">
                            {{ risk.text || $t("assessment.notAssessed") }}
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>
<script lang="ts">
import { PropType, computed, defineComponent, ref } from "vue";
import Icon from "../components/Icon.vue";
import { Project, ProjectStatus } from "@/lib/projects/projects";
import { CheckboxItem, DropdownItem } from "@/ui/inputs/input_helper";
import { useI18n } from "vue-i18n";

export default defineComponent({
    name: "CustomerCard",
    components: {
        Icon,
    },
    props: {
        details: {
            type: Object as PropType<Project>,
            required: true,
        },
        riskThreshold: {
            type: Object as PropType<CheckboxItem[]>,
            required: true,
        },
        employees: {
            type: Object as PropType<DropdownItem[]>,
            required: true,
        },
    },
    setup(props) {
        const { t } = useI18n();
        const expanded = ref(false);
        const handleClick = () => {
            expanded.value = !expanded.value;
        };

        const displayIcon = computed(() => {
            switch (props.details?.type) {
            case "person":
                return "blueBacgroundPerson";
            case "comapany" as string:
                return "company-with-blue-background";

            default:
                return "company-with-blue-background";
            }
        });
        const displayRiskStyle = computed(() => {
            const assesmentStatus = props?.details?.status;
            if (assesmentStatus) {
                switch (assesmentStatus) {
                case ProjectStatus.Unknown:
                    return {
                        text: t("customers.assesmentTypes.unknown"),
                        style: "bg-transparent border border-gray-300",
                    };
                case ProjectStatus.PendingInternal:
                    return {
                        text: t("customers.assesmentTypes.pendingRecepient"),
                        style: "bg-gray-300",
                    };
                case ProjectStatus.PendingExternal:
                    return {
                        text: t("customers.assesmentTypes.pendingRecepient"),
                        style: "bg-gray-300",
                    };
                case ProjectStatus.Completed:
                    return {
                        text: t("customers.assesmentTypes.completed"),
                        style: "bg-success-default",
                    };
                default:
                    return {
                        text: t("customers.assesmentTypes.unknown"),
                        style: "bg-transparent border border-gray-300",
                    };
                }
            } else {
                return {
                    text: t("customers.assesmentTypes.unknown"),
                    style: "bg-transparent border border-gray-300",
                };
            }
        });
        const riskText = (key: string, riskThreshold: CheckboxItem[]) => {
            const threshold = riskThreshold.find((rk) => rk.key === key);
            return threshold || { text: t("assessment.notAssessed"), color: "#D1D5DB", key: "NA" };
        };
        const risk = computed(() => {
            const projectAssessmentRisk = props?.details?.assessment?.risk;
            return riskText(projectAssessmentRisk, props?.riskThreshold);
        });

        const assignName = computed(() => {
            const assignedUser = props?.employees.find((em) => em.value === props?.details.assignee);
            return assignedUser?.text || "";
        });

        const workflow = computed(() => {
            return props?.details.workflow.split("-").map(wd => wd.charAt(0).toUpperCase() + wd.slice(1)).join(" ");
        });
        return {
            expanded,
            handleClick,
            displayIcon,
            displayRiskStyle,
            risk,
            assignName,
            workflow,
        };
    },
});
</script>
<style>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}
</style>
