import { BackOffice, Setting } from "../settings/settings";
import { Verified } from "../verified_plugin";

export type DeepPartial<T> = T extends object
    ? {
          [P in keyof T]?: DeepPartial<T[P]>;
      }
    : T;

export const updateBackofficeSettings = async (settings?: DeepPartial<BackOffice>): Promise<Setting> => {
    try {
        const response = await fetch("/api/aml/v1/backoffice/settings", {
            method: "PUT",
            headers: {
                ...Verified.instance.authHeaders,
                "content-type": "application/json",
            },
            body: JSON.stringify(settings),
        });

        const result = await response.json();

        if (!response.ok) {
            throw new Error(result);
        }

        return result as Setting;
    } catch (err) {
        console.error("Failed to update backoffice setting", err);
        throw err;
    }
};
