<template>
    <Layout>
        <Nav />
        <Main>
            <section>
                <div class="box-border font-roboto">
                    <div class="flex h-28 items-center gap-x-4 border-b border-gray-200 bg-white px-2 shadow-sm sm:gap-x-6">
                        <div class="flex flex-1 gap-x-4 justify-between lg:gap-x-6">
                            <div class="text-2xl sm:text-4xl font-roboto-slab px-5">
                                {{ $t('common.welcome') }} {{ $verified.identity.givenName }}!
                            </div>
                            <div class="flex items-center gap-x-4 lg:gap-x-6">
                                <!-- Right hand side icons goes here -->
                            </div>
                        </div>
                    </div>

                    <section class="flex flex-col sm:flex-row gap-4 min-h-screen m-2 sm:m-8">
                        <section class="order-2 sm:w-3/4 sm:order-1 sm:pr-8 sm:border-r sm:border-slate-300">
                            <div class="text-2xl border-b border-gray-300 pb-4 font-roboto-slab">
                                {{ $t('common.overview') }}
                            </div>
                            <div v-if="!events.loading && events.total < 1" class="pt-8">
                                <NoItem :text="$t('common.noEvents')" />
                            </div>
                            <!-- The events -->
                            <div class="flex flex-col gap-3 mt-4">
                                <div v-if="!events.loading">
                                    <label v-if="todaysEvents.length" class="text-gray-500 text-sm mb-2">{{ $t('common.days.today') }}</label>
                                    <ul role="list" class="space-y-3">
                                        <li
                                            v-for="(event, index) in todaysEvents"
                                            :key="index"
                                            class="rounded-md bg-white shadow-sm"
                                        >
                                            <EventCard :event="event" />
                                        </li>
                                    </ul>

                                    <label
                                        v-if="todaysEvents.length && olderEvents.length"
                                        class="text-gray-500 text-sm mt-5 mb-2"
                                    >{{ $t('common.older') }}</label>
                                    <ul role="list" class="space-y-3">
                                        <li
                                            v-for="(event, index) in olderEvents"
                                            :key="index"
                                            class="rounded-md bg-white shadow-sm"
                                        >
                                            <EventCard :event="event" />
                                        </li>
                                    </ul>
                                </div>
                                <div class="flex flex-col gap-3" v-if="events.loading">
                                    <div class="aml-loader h-[20px] w-[60px]"></div>
                                    <div
                                        v-for="i in 10"
                                        class="aml-loader h-[72px]"
                                        :key="i"
                                    ></div>
                                </div>
                            </div>
                            <Pagination
                                v-if="events.total > 0"
                                @change-page="paginate"
                                :items-count="events.total"
                                :items-per-page="events.limit"
                                :current-page="events.page"
                                class="mt-4"
                            />
                        </section>

                        <section class="order-1 sm:w-1/4 sm:order-2 sm:pl-4">
                            <div class="flex items-center justify-between">
                                <div class="text-2xl pb-2 font-roboto-slab">
                                    {{ $t('common.yourStats') }}
                                </div>
                            </div>

                            <div class="flex overflow-x-scroll sm:flex-col">
                                <StatsCard />
                            </div>
                        </section>
                    </section>
                </div>
            </section>
        </Main>
    </Layout>
</template>

<script lang="ts">
import { Icon } from "@/components";
import ActionButton from "@/components/ActionButton.vue";
import EventCard from "@/components/EventCard.vue";
import StatBox from "@/components/StatBox.vue";
import StatsCard from "@/components/StatsCard.vue";
import { format, isEqual } from "date-fns";
import { defineComponent } from "vue";
import DatePicker from "../components/DatePicker.vue";
import Layout from "../components/Layout.vue";
import Main from "../components/Main.vue";
import Nav from "../components/Nav.vue";
import Pagination from "../components/Pagination.vue";
import SelectBox from "../components/SelectBox.vue";
import { Event, queryEvents } from "../lib/events/events";
import NoItem from "@/components/NoItem.vue";

const today = new Date();

const isToday = (date: Date) => {
    return date ? isEqual(format(date, "yyyy-MM-dd"), format(today, "yyyy-MM-dd")) : false;
};

export default defineComponent({
    name: "Overview",
    components: {
        Nav,
        Layout,
        Main,
        Icon,
        ActionButton,
        Pagination,
        SelectBox,
        EventCard,
        DatePicker,
        StatsCard,
        StatBox,
        NoItem,
    },
    computed: {
        todaysEvents(): Event[] {
            return this.events.events.filter((event) => isToday(new Date(event.timestamp)));
        },
        olderEvents(): Event[] {
            return this.events.events.filter((event) => !isToday(new Date(event.timestamp)));
        },
    },
    data() {
        return {
            events: {
                loading: false,
                error: "",
                limit: 10,
                page: 1,
                total: 0,
                events: [] as Event[],
            },
        };
    },
    methods: {
        async paginate(newPage: number) {
            this.events.page = newPage;
            document.getElementById("aml-main")?.scrollTo(0, 0);
            await this.loadEvents();
        },
        async loadEvents() {
            this.events.loading = true;

            try {
                const res = await queryEvents({ limit: this.events.limit, skip: this.events.page * this.events.limit - this.events.limit });
                this.events.total = res.total;
                this.events.events = res.events;
            } catch (err) {
                console.error(err);
                this.events.error = "Failed to fetch latest events";
            } finally {
                this.events.loading = false;
            }
        },
    },
    async mounted() {
        await this.loadEvents();
    },
});
</script>
