<template>
    <div class="lg:flex lg:flex-col justify-between p-6 bg-white w-1/5 border-r-2 border-gray-200 hidden font-roboto overflow-y-auto">
        <nav class="flex gap-4 flex-col">
            <div class="flex gap-3 items-center mb-4">
                <Icon class="w-8 fill-primary-default" icon="aml:logo" />
                <h1 class="text-3xl">
                    {{ $t("core.portalName") }}
                </h1>
            </div>

            <div class="p-5 border border-gray-200 rounded-lg font-thin mb-4">
                <p class="text-light-gray text-center whitespace-pre-line">
                    {{ $t("core.navBar.msgLabel") }}
                </p>
                <button
                    @click="$router.push({ path: '/aml/customers/new' })"
                    class="text-primary-dark p-3 border border-gray-200 rounded-full w-full font-medium text-lg mt-5 hover:bg-blue-100 transition delay-20"
                >
                    {{ $t("core.navBar.btnNewCustomer") }}
                </button>
            </div>

            <ExperimentalNavRoutes v-if="$config.backoffice?.featureFlags?.experimentalNavbar" :routes="routes" />
            <NavRoutes v-else :routes="routes" />

        </nav>

        <div class="flex flex-col gap-3 text-xs pt-10">
            <div class="border border-gray-300 p-4 pb-1 rounded-lg">
                <label class="pb-2">{{ $t('core.navBar.lastAccessedProjects') }}</label>
                <ul role="list" class="">
                    <li v-for="customer in lastAccessedCustomers" :key="customer.id">
                        <a class="flex gap-4 items-center rounded-md text-xs pb-4" @click="$router.push({ path: `/aml/customers/${customer.id}/overview` })">
                            <Icon class="w-4 h-4" :icon="customer.type" />
                            <span class="truncate">{{ customer.name }}</span>
                        </a>
                    </li>
                </ul>
            </div>
            <!-- <div class="flex gap-2 text-xs border border-gray-300 rounded-lg items-center p-4">
                <Icon class="w-4 h-4" icon="feedback" />
                <span class="font-medium">Give us feedback</span>
            </div> -->
            <div class="text-center mt-2 text-gray-500">
                {{ 'Release version 3.' + $config.versionText }}
            </div>
        </div>

    </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { getLastViewedCustomers, UpdatedProjectData } from "@/lib/common/localStorage";
import NavRoutes from "./page_layout/NavRoutes.vue";
import ExperimentalNavRoutes from "./page_layout/ExperimentalNavRoutes.vue";
import Icon from "./Icon.vue";

export interface RouteDefinition {
    name: string;
    text: string;
    path: string;
    active: string[]; // the route paths from router.ts that triggers this nav entry to be active
    expanded: boolean;
    children: RouteDefinition[];
    childActive?: boolean;
}

export default defineComponent({
    // eslint-disable-next-line vue/multi-word-component-names
    name: "Nav",
    components: {
        Icon,
        NavRoutes,
        ExperimentalNavRoutes,
    },
    data() {
        return {
            routes: new Array<RouteDefinition>(),
            lastAccessedCustomers: [] as UpdatedProjectData[],
        };
    },
    methods: {

        getlastAccessedCustomers() {
            this.lastAccessedCustomers = getLastViewedCustomers();
        },

    },
    async created() {
        const policies = await this.$assets.getPolicies();
        this.$nextTick(() => {
            this.routes = this.$config.getNavRoutes(policies);
        });
    },
    mounted() {
        this.routes = this.$config.getNavRoutes([]);
        this.getlastAccessedCustomers();
    },
});
</script>
