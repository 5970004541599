import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "border rounded border-light border-solid text-sm" }
const _hoisted_2 = { class: "m-2" }
const _hoisted_3 = { class: "text-sm w-full font-bold mb-2" }
const _hoisted_4 = { class: "flex gap-1 justify-start items-center text-[12px]" }
const _hoisted_5 = {
  key: 0,
  class: "border rounded-full p-1 px-3 bg-[#F2CA61]"
}
const _hoisted_6 = {
  key: 1,
  class: "border rounded-full p-1 px-3 bg-green-300"
}
const _hoisted_7 = { class: "border rounded border-light border-solid text-sm mt-2" }
const _hoisted_8 = { class: "m-2" }
const _hoisted_9 = { class: "" }
const _hoisted_10 = { class: "flex" }
const _hoisted_11 = { class: "text-sm font-bold flex-1 mb-2" }
const _hoisted_12 = {
  key: 0,
  class: "flex flex-col gap-2"
}
const _hoisted_13 = ["onClick"]
const _hoisted_14 = { class: "relative flex-1 space-y-2" }
const _hoisted_15 = { class: "font-bold" }
const _hoisted_16 = { class: "hover:cursor-pointer text-sm" }
const _hoisted_17 = {
  key: 0,
  class: "absolute top-0 right-0 border rounded-full px-3 bg-[#F2CA61]"
}
const _hoisted_18 = {
  key: 1,
  class: "absolute top-0 right-0 border rounded-full px-3 bg-green-300"
}
const _hoisted_19 = {
  key: 0,
  class: "flex flex-col gap-2"
}
const _hoisted_20 = {
  key: 0,
  class: ""
}
const _hoisted_21 = { class: "font-bold" }
const _hoisted_22 = { class: "hover:cursor-pointer text-sm list-disc list-inside pl-2" }
const _hoisted_23 = {
  key: 1,
  class: ""
}
const _hoisted_24 = { class: "font-bold" }
const _hoisted_25 = { class: "text-sm hover:cursor-pointer list-disc list-inside pl-2" }
const _hoisted_26 = {
  key: 1,
  class: "flex flex-col gap-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('project.tabs.persons.screeningHitsDialog.summaryTitle')), 1),
        _createElementVNode("div", _hoisted_4, [
          (_ctx.screeningStatus?.sanction)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.$t('project.tabs.persons.screeningHitsDialog.sanctionsLong')), 1))
            : (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_ctx.$t('project.tabs.persons.screeningHitsDialog.noFlags')), 1))
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.$t('project.tabs.persons.screeningHitsDialog.hitsTitle')), 1)
          ]),
          (_ctx.hits.length)
            ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.hits, (hit, index) => {
                  return (_openBlock(), _createElementBlock("div", {
                    ref_for: true,
                    ref: 'hitRef_' + index,
                    key: index,
                    onClick: ($event: any) => (_ctx.toggleDetails(index)),
                    class: "border rounded-md border-light even:bg-white odd:bg-background border-solid p-2 break-all space-y-2 cursor-pointer shadow-sm"
                  }, [
                    _createElementVNode("div", _hoisted_14, [
                      _createElementVNode("div", _hoisted_15, _toDisplayString(_ctx.$t('project.tabs.persons.screeningHitsDialog.matchingNames')), 1),
                      _createElementVNode("div", _hoisted_16, _toDisplayString(hit?.match?.name), 1),
                      (hit?.hit?.sanction)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_17, _toDisplayString(_ctx.$t('project.tabs.persons.screeningHitsDialog.sanctionsLong')), 1))
                        : (_openBlock(), _createElementBlock("div", _hoisted_18, _toDisplayString(_ctx.$t('project.tabs.persons.screeningHitsDialog.noFlags')), 1))
                    ]),
                    (_ctx.showDetails[index])
                      ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                          (hit?.hit?.lists?.length)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
                                _createElementVNode("label", _hoisted_21, _toDisplayString(_ctx.$t('project.tabs.persons.screeningHitsDialog.sanctionLists')), 1),
                                _createElementVNode("ul", _hoisted_22, [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(hit?.hit?.lists || [], (s, i) => {
                                    return (_openBlock(), _createElementBlock("li", { key: i }, _toDisplayString(s), 1))
                                  }), 128))
                                ])
                              ]))
                            : _createCommentVNode("", true),
                          (hit?.hit?.notes?.length)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
                                _createElementVNode("label", _hoisted_24, _toDisplayString(_ctx.$t('project.tabs.persons.screeningHitsDialog.notes')), 1),
                                _createElementVNode("ul", _hoisted_25, [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(hit?.hit?.notes, (s, i) => {
                                    return (_openBlock(), _createElementBlock("li", { key: i }, _toDisplayString(s), 1))
                                  }), 128))
                                ])
                              ]))
                            : _createCommentVNode("", true)
                        ]))
                      : _createCommentVNode("", true)
                  ], 8, _hoisted_13))
                }), 128))
              ]))
            : _createCommentVNode("", true),
          (!_ctx.hits.length)
            ? (_openBlock(), _createElementBlock("div", _hoisted_26, _toDisplayString(_ctx.$t('project.tabs.persons.screeningHitsDialog.noHits')), 1))
            : _createCommentVNode("", true)
        ])
      ])
    ])
  ]))
}