import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "p-5 border border-grey-200 rounded-tl-md rounded-tr-md" }
const _hoisted_2 = { class: "mt-5 flex gap-5" }
const _hoisted_3 = { class: "flex-1 border border-gray-200 rounded-xl p-5" }
const _hoisted_4 = {
  key: 0,
  class: "flex-1 border border-gray-200 rounded-xl p-5"
}
const _hoisted_5 = { class: "-mb-2" }
const _hoisted_6 = {
  key: 0,
  class: "text-sm mt-4 italic"
}
const _hoisted_7 = { class: "flex items-center justify-between" }
const _hoisted_8 = { class: "my-5" }
const _hoisted_9 = { class: "my-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputLabel = _resolveComponent("InputLabel")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_InputGroup = _resolveComponent("InputGroup")!
  const _component_InputRow = _resolveComponent("InputRow")!
  const _component_CountryDropdown = _resolveComponent("CountryDropdown")!
  const _component_DatePicker = _resolveComponent("DatePicker")!
  const _component_WorkflowSelector = _resolveComponent("WorkflowSelector")!
  const _component_InputRadio = _resolveComponent("InputRadio")!
  const _component_AsyncStatus = _resolveComponent("AsyncStatus")!
  const _component_CustomButton = _resolveComponent("CustomButton")!
  const _component_ActionButton = _resolveComponent("ActionButton")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_InputRow, null, {
        default: _withCtx(() => [
          _createVNode(_component_InputGroup, { width: 6 }, {
            default: _withCtx(() => [
              _createVNode(_component_InputLabel, {
                "unique-id": "first-name",
                text: _ctx.$t('common.person_info.firstName'),
                required: true
              }, null, 8, ["text"]),
              _createVNode(_component_InputText, {
                modelValue: _ctx.person.firstName,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.person.firstName) = $event)),
                "unique-id": "first-name-input",
                placeholder: _ctx.$t('common.placeholders.firstName'),
                required: true
              }, null, 8, ["modelValue", "placeholder"])
            ]),
            _: 1
          }),
          _createVNode(_component_InputGroup, { width: 6 }, {
            default: _withCtx(() => [
              _createVNode(_component_InputLabel, {
                "unique-id": "last-name",
                text: _ctx.$t('common.person_info.lastName'),
                required: true
              }, null, 8, ["text"]),
              _createVNode(_component_InputText, {
                modelValue: _ctx.person.lastName,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.person.lastName) = $event)),
                "unique-id": "last-name-input",
                placeholder: _ctx.$t('common.placeholders.lastName'),
                required: true
              }, null, 8, ["modelValue", "placeholder"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_InputRow, null, {
        default: _withCtx(() => [
          _createVNode(_component_InputGroup, { width: 6 }, {
            default: _withCtx(() => [
              _createVNode(_component_CountryDropdown, {
                modelValue: _ctx.person.country,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.person.country) = $event)),
                label: "Country",
                required: true,
                placeholder: _ctx.$t('common.placeholders.country')
              }, null, 8, ["modelValue", "placeholder"])
            ]),
            _: 1
          }),
          _createVNode(_component_InputGroup, { width: 6 }, {
            default: _withCtx(() => [
              _createVNode(_component_InputLabel, {
                "unique-id": "date-of-birth",
                text: _ctx.$t('common.person_info.dateOfBirth'),
                required: true
              }, null, 8, ["text"]),
              _createElementVNode("div", null, [
                _createVNode(_component_DatePicker, {
                  modelValue: _ctx.person.dateOfBirth,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.person.dateOfBirth) = $event))
                }, null, 8, ["modelValue"])
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_InputRow, null, {
        default: _withCtx(() => [
          _createVNode(_component_InputGroup, { width: 6 }, {
            default: _withCtx(() => [
              _createVNode(_component_InputLabel, {
                "unique-id": "ssn",
                text: _ctx.$t('common.person_info.ssn')
              }, null, 8, ["text"]),
              _createVNode(_component_InputText, {
                modelValue: _ctx.person.ssn,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.person.ssn) = $event)),
                "unique-id": "ssn-input",
                placeholder: _ctx.$t('common.placeholders.ssn')
              }, null, 8, ["modelValue", "placeholder"])
            ]),
            _: 1
          }),
          _createVNode(_component_InputGroup, { width: 6 }, {
            default: _withCtx(() => [
              _createVNode(_component_InputLabel, {
                "unique-id": "email",
                text: _ctx.$t('common.person_info.email')
              }, null, 8, ["text"]),
              _createVNode(_component_InputText, {
                modelValue: _ctx.person.email,
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.person.email) = $event)),
                "unique-id": "email-input",
                placeholder: _ctx.$t('common.placeholders.email')
              }, null, 8, ["modelValue", "placeholder"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_WorkflowSelector, {
          class: "-mb-2",
          modelValue: _ctx.flowModel,
          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.flowModel) = $event)),
          conditions: _ctx.flowConditions
        }, null, 8, ["modelValue", "conditions"])
      ]),
      (_ctx.canCreateConfidentialProject)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_InputLabel, {
                text: _ctx.$t( 'newCustomer.selectConfidentiality' ),
                "unique-id": "confidentiality",
                required: true
              }, null, 8, ["text"]),
              _createVNode(_component_InputRadio, {
                modelValue: _ctx.confidentialRadio.selectedKey,
                "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.confidentialRadio.selectedKey) = $event)),
                items: _ctx.confidentialRadio.options,
                "unique-id": 'confidentiality'
              }, null, 8, ["modelValue", "items"])
            ]),
            (_ctx.confidentialRadio.selectedKey == 'confidential')
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_ctx.$t( 'newCustomer.confidentialityConfidentialInfo' )), 1))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", null, [
      _createVNode(_component_AsyncStatus, { progress: _ctx.progress }, null, 8, ["progress"])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("div", _hoisted_8, [
        _createVNode(_component_CustomButton, {
          text: _ctx.$t('newCustomer.btnReset'),
          "bg-color": "white",
          "text-color": "primary-default",
          "round-style": "full",
          "border-color": "muted",
          "on-click": _ctx.resetInformation
        }, null, 8, ["text", "on-click"])
      ]),
      _createElementVNode("div", _hoisted_9, [
        _createVNode(_component_ActionButton, {
          onBtnClicked: _ctx.handleAddPerson,
          disabled: !_ctx.canSubmit,
          loading: _ctx.progress.isWorking,
          class: "ml-auto mr-0 block"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('newCustomer.btnAddPerson')), 1)
          ]),
          _: 1
        }, 8, ["onBtnClicked", "disabled", "loading"])
      ])
    ])
  ], 64))
}