import { ApiResponse, parseLocalError, parseRemoteError } from "../error_parser";
import { Verified } from "../verified_plugin";
import { Project, ProjectType } from "./projects";

export type CreateProjectOptions = {
    name: string;
    type: ProjectType;
    identity?: string;
    country?: string;
    workflow: string;
    tags?: string[];
    notes?: string[];
    confidential?: boolean;
};

export const createProject = async (options: CreateProjectOptions): Promise<
    { project: Project | null, responseInfo: ApiResponse }
> => {
    try {
        const response = await fetch("/api/aml/v1/projects", {
            method: "POST",
            headers: {
                ...Verified.instance.authHeaders,
                "content-type": "application/json",
            },
            body: JSON.stringify({
                ...options,
            }),
        });

        const body = await response.json();

        if (!response.ok) {
            return {
                project: null,
                responseInfo: { isError: true, parsedError: parseRemoteError(body) },
            };
        }

        return {
            project: body as Project,
            responseInfo: { isError: false, parsedError: null },
        };
    } catch (error) {
        return {
            project: null,
            responseInfo: { isError: true, parsedError: parseLocalError(error) },
        };
    }
};
