<template>
    <div>

        <div v-if="loading">
            <HeaderWithAddIcon :text="'Approvals'" :hide-icon="true" />
            <LoadAnimationBar :height="60" />
            <LoadAnimationBar />
        </div>

        <div v-if="!loading">
            <HeaderWithAddIcon :text="'Approvals'" @clicked="showAddDialog" />

            <ApprovalSettingsItem
                v-for="(config, i) in approvals"
                :key="'ac' + i"
                :config="config"
                @edit="showEditDialog(config)"
                @delete="showDeleteDialog(config)"
            />

            <div v-if="approvals.length === 0">
                {{ 'No approval configurations' }}
            </div>
        </div>

        <ApprovalSettingsDialog
            v-if="dialog.visible"
            :config="dialog.config"
            @close-modal="dialog.visible = false"
            @add="onAdd"
            @edit="onEdit"
        />

        <PopupDialog
            :is-visible="deleteDialog.visible"
            :header="$t('common.actions.delete')"
            :icon="'trash-can'"
            :show-action-button="true"
            :show-cancel-button="true"
            :action-text="$t('common.actions.delete')"
            :action-icon="'trash-can'"
            :is-warning="true"
            @close-modal="deleteDialog.visible = false"
            @action="onDelete"
        >
            <PopupDialogPrompt
                :is-warning="true"
                :prompt-text="'Are you sure?'"
            />
        </PopupDialog>

    </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";

import LoadAnimationBar from "@/ui/LoadAnimationBar.vue";

import { updateSettings } from "@/lib/settings/update_settings";
import { querySettings } from "@/lib/settings/get_settings";
import { ApprovalConfig } from "@/lib/settings/settings";
import ApprovalSettingsItem from "./ApprovalSettingsItem.vue";
import HeaderWithAddIcon from "../HeaderWithAddIcon.vue";
import ApprovalSettingsDialog from "./ApprovalSettingsDialog.vue";
import { LocalizedString } from "@/lib/common/common";
import PopupDialog from "@/ui/PopupDialog.vue";
import PopupDialogPrompt from "@/ui/dialog/PopupDialogPrompt.vue";

export default defineComponent({
    name: "ApprovalSettings",
    components: {
        LoadAnimationBar,
        ApprovalSettingsItem,
        HeaderWithAddIcon,
        ApprovalSettingsDialog,
        PopupDialog,
        PopupDialogPrompt,
    },
    data() {
        return {
            loading: true,
            approvals: new Array<ApprovalConfig>(),
            dialog: { visible: false, config: null as ApprovalConfig | null },
            deleteDialog: { visible: false, key: "" },
        };
    },
    methods: {

        async onSomethingChanged() {
            const data = {
                approvalConfigs: this.approvals,
            };
            await updateSettings(data);
            const settings = await querySettings();
            this.$config.update(settings);
            this.loadApprovals();
        },

        showAddDialog() {
            this.dialog.config = null;
            this.dialog.visible = true;
        },

        showEditDialog(config: ApprovalConfig) {
            this.dialog.config = config;
            this.dialog.visible = true;
        },

        showDeleteDialog(config: ApprovalConfig) {
            this.deleteDialog.key = config.key;
            this.deleteDialog.visible = true;
        },

        onAdd(config: ApprovalConfig) {
            this.approvals.push(config);
            this.onSomethingChanged();
        },

        onEdit(config: ApprovalConfig) {
            this.approvals = this.approvals.map(x => x.key !== config.key ? x : config);
            this.onSomethingChanged();
        },

        onDelete() {
            for (const i in this.approvals) {
                if (this.approvals[i].key === this.deleteDialog.key) {
                    this.approvals.splice(Number(i), 1);
                }
            }
            this.deleteDialog.visible = false;
            this.onSomethingChanged();
        },

        loadApprovals() {
            this.approvals = this.$config.settings.approvalConfigs || [];
            this.loading = false;
        },

    },
    mounted() {
        this.loadApprovals();
    },
});
</script>
