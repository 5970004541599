<template>
    <Layout>
        <Nav />
        <Main class="px-16 py-5" v-if="!settings?.backoffice">
            No backoffice settings found..
        </Main>
        <Main class="px-16 py-5" v-if="settings?.backoffice">

            <div class="amlv3-settings-header">
                <div class="amlv3-settings-header-icon">
                    <FontIcon icon="key" :size="24" />
                </div>
                <div class="amlv3-settings-header-text">
                    <span>{{ 'Backoffice' }}</span>
                </div>
            </div>

            <TabControl
                :tabs="tabs"
                :selected-tab-key="selectedTab"
                @tab-changed="onTabChanged"
            />

            <div class="border-b border-gray-300 mb-8" />

            <!-- FEATURE FLAGS -->
            <section v-if="selectedTab == 'feature_flags' && settings.backoffice.featureFlags">
                <div>
                    <label class="font-bold">Feature flags</label>

                    <div class="flex gap-3">
                        <SwitchInput v-model="settings.backoffice.featureFlags.allowGtFlows" />
                        <label>Grant Thornton custom flows</label>
                    </div>

                    <div class="flex gap-3">
                        <SwitchInput v-model="settings.backoffice.featureFlags.experimentalNavbar" />
                        <label>Experimental Navbar</label>
                    </div>

                    <div class="flex gap-3">
                        <SwitchInput v-model="settings.backoffice.featureFlags.autoMonitorCompanyProjects" />
                        <label>Automatically monitor company projects when created</label>
                    </div>

                    <div class="flex gap-3">
                        <SwitchInput v-model="settings.backoffice.featureFlags.personScreenWithDob" />
                        <label>Include date of birth as screening search parameter if available</label>
                    </div>
                </div>
            </section>

            <!-- LOOKUP -->
            <section v-if="selectedTab == 'lookup' && settings.backoffice.lookup">
                <div>
                    <label class="font-bold">Default country</label>
                    <CountryDropdown
                        class="max-w-[300px]"
                        v-model="settings.backoffice.lookup.defaultCountry"
                        :lowercase="true"
                    />
                </div>

                <div class="my-10"></div>

                <div>
                    <label class="font-bold">Enabled countries</label>

                    <div class="flex gap-3">
                        <SwitchInput v-model="settings.backoffice.lookup.countries.no" />
                        <label>Norway</label>
                    </div>
                    <div class="flex gap-3">
                        <SwitchInput v-model="settings.backoffice.lookup.countries.se" />
                        <label>Sweden</label>
                    </div>
                    <div class="flex gap-3">
                        <SwitchInput v-model="settings.backoffice.lookup.countries.fi" />
                        <label>Finland</label>
                    </div>
                    <div class="flex gap-3">
                        <SwitchInput v-model="settings.backoffice.lookup.countries.dk" />
                        <label>Denmark</label>
                    </div>
                </div>
            </section>

            <div class="my-10"></div>

            <div>
                <ActionButton
                    @btn-clicked="save"
                    :disabled="!changed"
                    :loading="saving"
                    class="block text-xs font-bold"
                >
                    Save changes
                </ActionButton>
            </div>

            <div class="text-red-600 mt-3 text-sm" v-if="saveError">
                {{ saveError }}
            </div>
            <div class="text-green-600 mt-3 text-sm" v-if="saveSuccess">
                {{ saveSuccess }}
            </div>

        </Main>
    </Layout>
</template>
<script lang="ts">
import { Layout, Main, Nav } from "@/components/index";
import { defineComponent } from "vue";
import { Setting } from "@/lib/settings/settings";
import FontIcon from "@/ui/FontIcon.vue";
import { TabControlTab } from "@/types/ui_types";
import TabControl from "@/ui/TabControl.vue";
import CountryDropdown from "@/ui/inputs/CountryDropdown.vue";
import InputGroup from "@/ui/inputs/InputGroup.vue";
import InputLabel from "@/ui/inputs/InputLabel.vue";
import InputRadio from "@/ui/inputs/InputRadio.vue";
import SwitchInput from "@/components/SwitchInput.vue";
import ActionButton from "@/components/ActionButton.vue";
import { updateBackofficeSettings } from "@/lib/backoffice/backoffice_settings";
import { querySettings } from "@/lib/settings/get_settings";

export default defineComponent({
    // eslint-disable-next-line vue/multi-word-component-names
    name: "Backoffice",
    components: {
        Layout,
        Main,
        Nav,
        FontIcon,
        TabControl,
        InputGroup,
        InputLabel,
        CountryDropdown,
        InputRadio,
        SwitchInput,
        ActionButton,
    },
    data() {
        return {
            section: "",
            sectionName: "",

            tabs: [
                {
                    key: "feature_flags",
                    text: "Feature flags",
                    rawText: "Feature flags",
                }, {
                    key: "lookup",
                    text: "Lookup",
                    rawText: "Lookup",
                }] as TabControlTab[],
            selectedTab: "feature_flags",

            originalSettings: {} as Setting,
            settings: {} as Setting,
            saving: false,
            saveSuccess: "",
            saveError: "",
        };
    },
    computed: {
        changed(): boolean {
            return JSON.stringify(this.originalSettings) !== JSON.stringify(this.settings);
        },
    },
    methods: {
        onTabChanged(key: string) {
            this.selectedTab = key;
        },
        async save() {
            this.saveError = "";
            this.saveSuccess = "";
            this.saving = true;
            try {
                await updateBackofficeSettings(this.settings.backoffice);
                const settings = await querySettings();
                this.$config.update(settings);
                this.setSettings();
                this.saveSuccess = "Successfully saved backoffice settings";
            } catch (err) {
                this.saveError = "Failed to save backoffice settings";
            } finally {
                this.saving = false;
            }
        },
        setSettings() {
            this.originalSettings = this.$config.rawSettings;
            this.settings = { ...this.$config.rawSettings };
        },
    },

    watch: {
        selectedTab() {
            this.setSettings();
            this.saveSuccess = "";
            this.saveError = "";
        },
    },

    mounted() {
        this.setSettings();
    },

    created() {
        if (!this.$verified.isBackofficeUser) {
            alert("No access");
            throw new Error("No access");
        }
    },
});
</script>
<style>
.amlv3-settings-header {
    display: flex;
    margin-bottom: 15px;
}

.amlv3-settings-header-icon {
    margin-top: 4px;
    color: #218BCB;
}

.amlv3-settings-header-text {
    margin-left: 15px;
    font-family: "Roboto Slab";
    font-size: 28px;
    font-weight: 400;
    color: #444444;
}
</style>
