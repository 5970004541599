import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0c5f407a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "amlv3-asslist-items" }
const _hoisted_2 = { class: "amlv3-asslist-item-text" }
const _hoisted_3 = { class: "amlv3-asslist-item-name" }
const _hoisted_4 = { class: "amlv3-asslist-item-actions" }
const _hoisted_5 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MenuInfoLine = _resolveComponent("MenuInfoLine")!
  const _component_FontIcon = _resolveComponent("FontIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.assessments, (assessment, i) => {
      return (_openBlock(), _createElementBlock("div", {
        key: 'pa' + i,
        class: "amlv3-asslist-item"
      }, [
        _createElementVNode("div", {
          class: "amlv3-asslist-item-icon",
          style: _normalizeStyle({backgroundColor: _ctx.getRiskInitial(assessment).color})
        }, _toDisplayString(_ctx.getRiskInitial(assessment).str), 5),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.getAssessmentName(assessment)), 1),
          _createVNode(_component_MenuInfoLine, {
            info1: _ctx.getRiskText(assessment),
            info2: assessment.sealedBy?.name
          }, null, 8, ["info1", "info2"])
        ]),
        _createElementVNode("div", _hoisted_4, [
          (assessment.sealed)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: "amlv3-asslist-item-action",
                onClick: ($event: any) => (_ctx.previewReport(assessment.id))
              }, [
                _createVNode(_component_FontIcon, {
                  icon: 'file-pdf',
                  size: 18
                })
              ], 8, _hoisted_5))
            : _createCommentVNode("", true)
        ])
      ]))
    }), 128))
  ]))
}