import { Verified } from "../verified_plugin";

export const updateCompanyMonitoringStatus = async (status: boolean, projectId: string) => {
    const baseUrl = "/api/aml/v1/projects";
    try {
        const response = await fetch(`${baseUrl}/${projectId}/monitor`, {
            method: "PUT",
            headers: {
                ...Verified.instance.authHeaders,
                "content-type": "application/json",
            },
            body: JSON.stringify({
                monitor: status,
            }),
        });
        const res = await response.json();

        if (!response.ok) {
            console.log(response);
        }
        return res;
    } catch (error) {
        console.log(error);
        throw (error);
    }
};

export interface ProjectScreening {
    namespace: string;
    projectId: string;
    source: string;
    externalSourceId: string;
    falsePositive: boolean;
    match: {
        matchId: string;
        aliasId: string;
        sanction: boolean;
        name: string;
        matchedName: {
            text: string;
            isMatch: boolean;
        }[];
        score: number;
    };
    hit: {
        sanction: boolean;
        sie: boolean;
        lists: string[];
        notes: string[];
    };
}

export const getCompanyScreeningHits = async(projectId: string): Promise<{ hits: ProjectScreening[], total: number }> => {
    try {
        const result = await fetch(`/api/aml/v1/projects/${projectId}/hits`, {
            method: "get",
            headers: {
                ...Verified.instance.authHeaders,
                "content-type": "application/json",
            },
        });
        const screening = await result.json();
        console.log(screening, "screeing hit");
        return screening;
    } catch (error) {
        throw new Error(`${error}`);
    }
};
