import i18n, { i18localeToVerified } from "@/i18n";

export interface LocalizedString {
    key?: string;
    value?: string;
    en_EN?: string;
    nb_NO?: string;
    sv_SE?: string;
    fi_FI?: string;
}

export interface LocalizedStringMap {
    [key: string]: LocalizedString;
}

export enum LocalizedStringValidation {
    CurrentOnly = "current-only",
    AnyLanguage = "any-language"
}

export const validateLocalizedString = (
    localizedString: LocalizedString,
    mode: LocalizedStringValidation,
): boolean => {
    const langKey = i18localeToVerified(i18n.global.locale.value);

    if (mode === LocalizedStringValidation.CurrentOnly) {
        if (localizedString[langKey]) {
            return true;
        }
    }

    if (mode === LocalizedStringValidation.AnyLanguage) {
        if (localizedString.value || localizedString.en_EN || localizedString.nb_NO || localizedString.sv_SE || localizedString.fi_FI) {
            return true;
        }
    }

    return false;
};
export interface valueWithsources {
    value: string | object;
    sources: {
        source: string;
        updatedAt: number;
    }[];
}
