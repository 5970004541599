import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0f2290ce"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "" }
const _hoisted_2 = {
  key: 0,
  class: "amlv3-workflow-selector-error"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputLabel = _resolveComponent("InputLabel")!
  const _component_InputRadio = _resolveComponent("InputRadio")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_InputLabel, {
      text: _ctx.$t( 'newCustomer.selectWorkflow' ),
      "unique-id": "workflow",
      required: true
    }, null, 8, ["text"]),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_InputRadio, {
        modelValue: _ctx.flowModel,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.flowModel) = $event)),
        items: _ctx.workflows,
        "unique-id": 'flowradio',
        onInput: _ctx.onInput
      }, null, 8, ["modelValue", "items", "onInput"])
    ]),
    (_ctx.workflows.length === 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.$t('newCustomer.noAvailableWorkflow')), 1))
      : _createCommentVNode("", true)
  ]))
}