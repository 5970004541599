import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createVNode as _createVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 1,
  class: "mt-5"
}
const _hoisted_3 = { key: 0 }
const _hoisted_4 = {
  key: 1,
  class: "mt-4"
}
const _hoisted_5 = {
  key: 2,
  class: "mt-4"
}
const _hoisted_6 = { class: "mt-4" }
const _hoisted_7 = { class: "mt-4" }
const _hoisted_8 = { class: "mt-4" }
const _hoisted_9 = {
  key: 2,
  class: "mt-5"
}
const _hoisted_10 = {
  key: 3,
  class: "mt-5"
}
const _hoisted_11 = {
  key: 4,
  class: "mt-5"
}
const _hoisted_12 = {
  key: 5,
  class: "mt-5"
}
const _hoisted_13 = {
  key: 6,
  class: "mt-5"
}
const _hoisted_14 = {
  key: 7,
  class: "mt-5"
}
const _hoisted_15 = {
  key: 8,
  class: "mt-5"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MenuTabs = _resolveComponent("MenuTabs")!
  const _component_RiskSliderBox = _resolveComponent("RiskSliderBox")!
  const _component_RiskComments = _resolveComponent("RiskComments")!
  const _component_FlowRequirements = _resolveComponent("FlowRequirements")!
  const _component_OtherProjects = _resolveComponent("OtherProjects")!
  const _component_PreviousAssessments = _resolveComponent("PreviousAssessments")!
  const _component_MenuLastEvents = _resolveComponent("MenuLastEvents")!
  const _component_FormList = _resolveComponent("FormList")!
  const _component_ApprovalList = _resolveComponent("ApprovalList")!
  const _component_MenuTabEvents = _resolveComponent("MenuTabEvents")!
  const _component_MenuAttachments = _resolveComponent("MenuAttachments")!
  const _component_DependencyControl = _resolveComponent("DependencyControl")!
  const _component_MenuAssessmentList = _resolveComponent("MenuAssessmentList")!
  const _component_MenuScreenResult = _resolveComponent("MenuScreenResult")!
  const _component_NewAssessmentDialog = _resolveComponent("NewAssessmentDialog")!

  return (_ctx.project)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.isLoaded)
          ? (_openBlock(), _createBlock(_component_MenuTabs, {
              key: 0,
              title: _ctx.$t('project.overviewMenu.title'),
              tabs: _ctx.tabs,
              "selected-tab": _ctx.selectedTab,
              onTab: _ctx.onTabChanged
            }, null, 8, ["title", "tabs", "selected-tab", "onTab"]))
          : _createCommentVNode("", true),
        (_ctx.selectedTab == 'overview')
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              (_ctx.matrixDefinition)
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                    (_ctx.riskConfig)
                      ? (_openBlock(), _createBlock(_component_RiskSliderBox, {
                          key: 0,
                          project: _ctx.project,
                          assessment: _ctx.currentAssessment,
                          "risk-config": _ctx.riskConfig,
                          "matrix-definition": _ctx.matrixDefinition,
                          onRequestProjectRefresh: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('request-project-refresh', $event))),
                          onNewAssessment: _cache[1] || (_cache[1] = ($event: any) => (_ctx.newDialog.visible = true))
                        }, null, 8, ["project", "assessment", "risk-config", "matrix-definition"]))
                      : _createCommentVNode("", true)
                  ]))
                : _createCommentVNode("", true),
              (_ctx.matrixDefinition && _ctx.matrixDefinition.options.manualScore)
                ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                    _createVNode(_component_RiskComments, {
                      project: _ctx.project,
                      assessment: _ctx.currentAssessment,
                      "matrix-definition": _ctx.matrixDefinition,
                      onRequestProjectRefresh: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('request-project-refresh', $event)))
                    }, null, 8, ["project", "assessment", "matrix-definition"])
                  ]))
                : _createCommentVNode("", true),
              (!_ctx.currentAssessment.sealed)
                ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                    _createVNode(_component_FlowRequirements, {
                      project: _ctx.project,
                      requirements: _ctx.renderData.requirements,
                      onSeal: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('seal', $event)))
                    }, null, 8, ["project", "requirements"])
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_6, [
                _createVNode(_component_OtherProjects, {
                  project: _ctx.project,
                  onMenuTab: _ctx.onTabChanged,
                  onProjectLink: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('project-link', $event)))
                }, null, 8, ["project", "onMenuTab"])
              ]),
              _createElementVNode("div", _hoisted_7, [
                _createVNode(_component_PreviousAssessments, {
                  assessments: _ctx.assessments,
                  project: _ctx.project,
                  onMenuTab: _ctx.onTabChanged,
                  onFilePreview: _cache[5] || (_cache[5] = ($event: any) => (_ctx.$emit('file-preview', $event)))
                }, null, 8, ["assessments", "project", "onMenuTab"])
              ]),
              _createElementVNode("div", _hoisted_8, [
                _createVNode(_component_MenuLastEvents, {
                  project: _ctx.project,
                  onMenuTab: _ctx.onTabChanged
                }, null, 8, ["project", "onMenuTab"])
              ])
            ]))
          : _createCommentVNode("", true),
        (_ctx.selectedTab == 'forms')
          ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
              _createVNode(_component_FormList, {
                ref: "menuFormList",
                "context-type": _ctx.FormContextType.Project,
                "context-id": _ctx.project.id,
                forms: _ctx.forms,
                project: _ctx.project,
                "initial-key": _ctx.initialKeyForm,
                onRequestProjectRefresh: _cache[6] || (_cache[6] = ($event: any) => (_ctx.$emit('request-project-refresh', $event)))
              }, null, 8, ["context-type", "context-id", "forms", "project", "initial-key"])
            ]))
          : _createCommentVNode("", true),
        (_ctx.selectedTab == 'approvals')
          ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
              _createVNode(_component_ApprovalList, {
                ref: "menuApprovalList",
                project: _ctx.project,
                assessment: _ctx.currentAssessment,
                "initial-key": _ctx.initialKeyApproval,
                onRequestProjectRefresh: _cache[7] || (_cache[7] = ($event: any) => (_ctx.$emit('request-project-refresh', $event)))
              }, null, 8, ["project", "assessment", "initial-key"])
            ]))
          : _createCommentVNode("", true),
        (_ctx.selectedTab == 'events')
          ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
              _createVNode(_component_MenuTabEvents, {
                project: _ctx.project,
                onRequestProjectRefresh: _cache[8] || (_cache[8] = ($event: any) => (_ctx.$emit('request-project-refresh', $event)))
              }, null, 8, ["project"])
            ]))
          : _createCommentVNode("", true),
        (_ctx.selectedTab == 'attachments')
          ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
              _createVNode(_component_MenuAttachments, {
                attachments: _ctx.project.attachments,
                "project-id": _ctx.project.id,
                onRequestProjectRefresh: _cache[9] || (_cache[9] = ($event: any) => (_ctx.$emit('request-project-refresh', $event)))
              }, null, 8, ["attachments", "project-id"])
            ]))
          : _createCommentVNode("", true),
        (_ctx.selectedTab == 'dependency')
          ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
              _createVNode(_component_DependencyControl, {
                project: _ctx.project,
                dependency: _ctx.dependency,
                onRequestProjectRefresh: _cache[10] || (_cache[10] = ($event: any) => (_ctx.$emit('request-project-refresh', $event)))
              }, null, 8, ["project", "dependency"])
            ]))
          : _createCommentVNode("", true),
        (_ctx.selectedTab == 'assessments')
          ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
              _createVNode(_component_MenuAssessmentList, {
                assessments: _ctx.assessments,
                project: _ctx.project,
                onFilePreview: _cache[11] || (_cache[11] = ($event: any) => (_ctx.$emit('file-preview', $event)))
              }, null, 8, ["assessments", "project"])
            ]))
          : _createCommentVNode("", true),
        (_ctx.selectedTab == 'screen')
          ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
              _createVNode(_component_MenuScreenResult, { project: _ctx.project }, null, 8, ["project"])
            ]))
          : _createCommentVNode("", true),
        (_ctx.newDialog.visible)
          ? (_openBlock(), _createBlock(_component_NewAssessmentDialog, {
              key: 9,
              project: _ctx.project,
              onCloseModal: _cache[12] || (_cache[12] = ($event: any) => (_ctx.newDialog.visible = false)),
              onRequestProjectRefresh: _cache[13] || (_cache[13] = ($event: any) => (_ctx.$emit('request-project-refresh', $event)))
            }, null, 8, ["project"]))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}