import { App } from "vue";
import { WebappServices } from "./services";

// Vue plugin to make it easier to work with data and services from the Webapp in components.
// Installs as a global property $verified that can be used in any component as `this.$verified`

export class Verified {
    private static _instance: Verified;

    constructor(private _services: WebappServices) {
        if (!_services) {
            console.error("verified_plugin: Did not get services from webapp");
        }

        Verified._instance = this;
    }

    static get instance() {
        if (!this._instance) {
            throw new Error("Cannot access Verified plugin instance before it's been constructed");
        }
        return Verified._instance;
    }

    get services() {
        return this._services;
    }

    get companyId() {
        // CompanyService does not immediately have the companyid available
        // after a full page refresh, instead of waiting for it to update
        // we just use the one defined in the url as that is what the
        // CompanyService itself will use

        let companyId = this.services.CompanyService.getInstance().getCurrent()?.id;
        if (!companyId) {
            const qs = location.href.split("?");
            const urlParams = new URLSearchParams(qs[qs.length - 1]);
            companyId = urlParams.get("companyId") || "";
        }
        return companyId || "";
    }

    get accessToken() {
        // IdentityService will always have the token available afaik,
        // but if not we can also fetch it ourselves from localstorage
        // if needed, similar to companyId

        return this.services.IdentityService.getInstance().getRawToken();
    }

    get uid() {
        return this.services.IdentityService.getInstance().getIdentity().uid;
    }

    get identity() {
        return this.services.IdentityService.getInstance().getIdentity();
    }

    get isBackofficeUser() {
        return !!this.identity?.roles?.find(role => role.name === "god" || role.name === "ve-backoffice");
    }

    get authHeaders() {
        return {
            authorization: `JWT ${this.accessToken}`,
            "x-namespace": `/companies/${this.companyId}`,
        };
    }

    get currentLanguage() {
        return this.services.TranslationService.getInstance().getLanguage();
    }

    get namespace() {
        return `/companies/${this.companyId}`;
    }
}

export default (services: WebappServices) => {
    return {
        install(app: App, options?: any) {
            app.config.globalProperties.$verified = new Verified(services);
        },
    };
};

declare module "@vue/runtime-core" {
    interface ComponentCustomProperties {
        $verified: Verified;
    }
}
