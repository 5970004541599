<template>
    <Listbox
        :model-value="modelValue"
        @update:model-value="(value) => updateModelValue(value)"
        :disabled="disabled"
        v-slot="{ open }"
        :multiple="multiple"
    >
        <div class="relative">
            <ListboxLabel v-if="label" class="block">
                {{ label }}

                <span class="ml-1 text-danger-default" v-if="required">
                    {{ "*" }}
                </span>
            </ListboxLabel>
            <ListboxButton
                as="div"
                :class="[bgColor, borderRadius]"
                class="relative w-full flex  gap-3 items-center border border-light border-solid cursor-default h-[42px] py-0 pl-3 pr-2 text-left"
            >
                <FlagIcon v-if="showFlag && selectedOptionValue" :country="selectedOptionValue?.toUpperCase()" />
                <span v-if="selectedOptionLabel" class="block truncate flex-1">{{ selectedOptionLabel }}</span>
                <span v-else class="block truncate text-light-gray flex-1">{{ placeholder }}</span>
                <FontIcon class="" :icon="open ? 'angle-up' : 'angle-down'" />
            </ListboxButton>

            <ListboxOptions
                class="absolute z-50 outline-none list-none pl-0 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 border border-light border-solid"
            >
                <ListboxOption
                    v-for="option in options"
                    :key="option.value"
                    :value="option.value"
                    v-slot="{ active, selected }"
                >
                    <li :class="[active ? 'bg-gray-100' : '', 'relative flex items-center gap-3 cursor-default select-none py-2 px-3']">
                        <FlagIcon v-if="showFlag" :country="option.value?.toUpperCase()" />
                        <span :class="[selected ? 'font-medium' : 'font-normal', 'block truncate']">{{ option.label }}</span>
                        <Icon
                            v-if="selected"
                            icon="blue-tick-mark"
                            class="ml-auto w-3 h-3 mr-3 inset-y-0"
                        />
                    </li>
                </ListboxOption>
            </ListboxOptions>
        </div>
    </Listbox>
</template>

<script lang="ts">
import FlagIcon from "@/components/FlagIcon.vue";
import { Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions } from "@headlessui/vue";
import { defineComponent, PropType } from "vue";
import Icon from "../components/Icon.vue";
import FontIcon from "@/ui/FontIcon.vue";

export default defineComponent({
    components: {
        Listbox,
        ListboxButton,
        ListboxLabel,
        ListboxOptions,
        ListboxOption,
        Icon,
        FlagIcon,
        FontIcon,
    },
    props: {
        modelValue: {
            type: [String, Number, Array],
            required: true,
        },
        multiple: {
            type: Boolean,
            default: false,
        },
        optionIcon: { type: Boolean as PropType<boolean>, default: false },
        label: String as PropType<string>,
        placeholder: {
            type: String,
            default: "Select option",
        },
        options: {
            type: Array as PropType<{ label: string; value: string }[]>,
            required: true,
        },
        disabled: Boolean as PropType<boolean>,
        bgColor: {
            type: String,
            default: "bg-transparent",
        },
        borderRadius: {
            type: String,
            default: "rounded-md",
        },
        showFlag: {
            type: Boolean,
            default: true,
        },
        required: {
            type: Boolean,
            default: false,
        },
    },
    emits: ["update:modelValue"],
    data() {
        return {
            selectedOption: this.options?.find((option) => option.value === "SE")?.value ?? this.options[0]?.value,
        };
    },
    methods: {
        updateModelValue(value: string) {
            console.log("methods", value);
            this.$emit("update:modelValue", value);
        },
    },
    watch: {
        modelValue(oldValue, newValue) {
            if (newValue === "") {
                this.selectedOption = "";
            }
        },
    },
    computed: {
        selectedOptionLabel(): string {
            return this.options
                ?.filter((option) => {
                    if (Array.isArray(this.modelValue)) {
                        return this.modelValue.includes(option.value);
                    }
                    console.log("label", this.modelValue);
                    return option.value === this.modelValue;
                })
                .map((option) => option.label)
                .join(",");
        },
        selectedOptionValue(): string {
            return this.options?.find((option) => option.value === this.modelValue)?.value ?? "";
        },
    },
});
</script>

<style>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.3s ease;
}
.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}
</style>
