import { Verified } from "../verified_plugin";

export const getProjectRiskStatistics = async (): Promise<{risk: string, count: number}[]> => {
    try {
        const response = await fetch("/api/aml/v1/statistics/riskdistribution/", {
            method: "GET",
            headers: {
                ...Verified.instance.authHeaders,
                "content-type": "application/json",
            },
        });

        const body = await response.json();

        if (!response.ok) {
            throw new Error(body);
        }

        return body as {risk: string, count: number}[];
    } catch (err) {
        console.error("Failed to get project risk statistics", err);
        throw err;
    }
};
