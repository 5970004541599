<template>
    <div>
        <div class="border rounded border-light border-solid text-sm">
            <div class="m-2">
                <div class="text-sm w-full font-bold mb-2">
                    {{ $t('project.tabs.persons.screeningHitsDialog.summaryTitle') }}
                </div>
                <div class="flex gap-1 justify-start items-center text-[12px]">
                    <div v-if="screeningStatus?.sanction" class="border rounded-full p-1 px-3 bg-[#F2CA61]">
                        {{ $t('project.tabs.persons.screeningHitsDialog.sanctionsLong') }}
                    </div>
                    <div v-else class="border rounded-full p-1 px-3 bg-green-300">
                        {{ $t('project.tabs.persons.screeningHitsDialog.noFlags') }}
                    </div>
                </div>

            </div>
        </div>

        <div class="border rounded border-light border-solid text-sm mt-2">
            <div class="m-2">
                <div class="">
                    <div class="flex">
                        <div class="text-sm font-bold flex-1 mb-2">
                            {{ $t('project.tabs.persons.screeningHitsDialog.hitsTitle') }}
                        </div>
                    </div>

                    <div v-if="hits.length" class="flex flex-col gap-2">
                        <div
                            v-for="(hit, index) in hits"
                            :ref="'hitRef_' + index"
                            :key="index"
                            @click="toggleDetails(index)"
                            class="border rounded-md border-light even:bg-white odd:bg-background border-solid p-2 break-all space-y-2 cursor-pointer shadow-sm"
                        >
                            <div class="relative flex-1 space-y-2">
                                <div class="font-bold">
                                    {{ $t('project.tabs.persons.screeningHitsDialog.matchingNames') }}
                                </div>
                                <div class="hover:cursor-pointer text-sm">
                                    {{ hit?.match?.name }}
                                </div>
                                <div v-if="hit?.hit?.sanction" class="absolute top-0 right-0 border rounded-full px-3 bg-[#F2CA61]">
                                    {{ $t('project.tabs.persons.screeningHitsDialog.sanctionsLong') }}
                                </div>
                                <div v-else class="absolute top-0 right-0 border rounded-full px-3 bg-green-300">
                                    {{ $t('project.tabs.persons.screeningHitsDialog.noFlags') }}
                                </div>
                            </div>

                            <!--Hide this section onclick-->
                            <div v-if="showDetails[index]" class="flex flex-col gap-2">
                                <div v-if="hit?.hit?.lists?.length" class=" ">
                                    <label class="font-bold">
                                        {{ $t('project.tabs.persons.screeningHitsDialog.sanctionLists') }}
                                    </label>
                                    <ul class=" hover:cursor-pointer text-sm list-disc list-inside pl-2">
                                        <li

                                            v-for="(s, i) in hit?.hit?.lists || []"
                                            :key="i"
                                        >
                                            {{ s }}
                                        </li>
                                    </ul>
                                </div>

                                <div v-if="hit?.hit?.notes?.length" class=" ">
                                    <label class="font-bold">
                                        {{ $t('project.tabs.persons.screeningHitsDialog.notes') }}
                                    </label>
                                    <ul class="text-sm hover:cursor-pointer list-disc list-inside pl-2">
                                        <li

                                            v-for="(s, i) in hit?.hit?.notes"
                                            :key="i"
                                        >
                                            {{ s }}
                                        </li>
                                    </ul>

                                </div>
                            </div>

                        </div>
                    </div>
                    <div v-if="!hits.length" class="flex flex-col gap-2">
                        {{ $t('project.tabs.persons.screeningHitsDialog.noHits') }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { ScreeningStatus, HitStatus } from "@/lib/persons/person";
import { Project } from "@/lib/projects/projects";
import { getCompanyScreeningHits, ProjectScreening } from "@/lib/screening/screen_company";
import { defineComponent, type PropType } from "vue";

export default defineComponent({
    name: "MenuScreenResult",
    components: {

    },
    props: {
        project: {
            type: {} as PropType<Project>,
            required: true,
        },
    },
    data() {
        return {
            HitStatus,
            loading: false,
            error: "",
            hits: [] as ProjectScreening[],
            showDetails: [] as boolean[],
        };
    },
    computed: {
        screeningStatus(): ScreeningStatus {
            return this.project.screeningStatus;
        },
    },
    methods: {
        async loadHits() {
            try {
                const res = await getCompanyScreeningHits(this.project.id);
                this.hits = res.hits;
                this.showDetails = this.hits.map(() => false);
            } catch (err) {
                this.error = "Failed to load screening results";
            } finally {
                this.loading = false;
            }
        },
        toggleDetails(index: number) {
            if (this.showDetails.length > 0) {
                this.showDetails.forEach((detail, i) => {
                    if (i !== index) {
                        this.showDetails[i] = false;
                    }
                });
            }

            this.showDetails[index] = !this.showDetails[index];
        },
    },
    mounted() {
        this.loadHits();
    },
});
</script>
