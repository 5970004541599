<template>
    <div>
        <Menu as="div" class="relative inline-block text-left w-full">
            <div>
                <MenuButton
                    @click="menuBtnClicked"
                    class="border border-solid border-gray-200 flex gap-2 w-full rounded-md p-3 h-[40px] text-sm font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75 items-center bg-transparent"
                >
                    <FontIcon
                        v-if="selectedItem?.icon"
                        :icon="selectedItem?.icon"
                        :size="12"
                        :class="selectedItem?.iconCls ? selectedItem?.iconCls : 'text-gray-600'"
                    />
                    <div class="flex-1 text-left">
                        <span v-if="selectedItem">{{ selectedItem.text }}</span>
                        <span class="text-gray-400 font-normal" v-if="!selectedItem">{{ placeholder }}</span>
                    </div>
                    <svg
                        v-if="!loading"
                        class="w-2.5 h-2.5 ms-3 justify-end"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 10 6"
                    >
                        <path
                            stroke="currentColor"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="m1 1 4 4 4-4"
                        />
                    </svg>
                    <svg
                        v-if="loading"
                        aria-hidden="true"
                        role="status"
                        class="inline w-4 h-4 text-white animate-spin"
                        viewBox="0 0 100 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#cccccc" />
                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
                    </svg>
                </MenuButton>
            </div>

            <transition>
                <MenuItems
                    class="absolute left-0 mt-2 min-w-[250px] origin-top-left divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none z-10"
                >
                    <div class="p-2">
                        <div v-if="searchable" class="relative mb-2">
                            <input
                                ref="search"
                                class="border border-solid border-gray-200 rounded-md text-sm p-2 pl-8 w-full placeholder:text-sm outline-none"
                                v-model="search"
                                :placeholder="$t('common.actions.search')"
                                type="text"
                            />
                            <FontIcon
                                icon="magnifying-glass"
                                :size="14"
                                class="text-gray-400 absolute left-3 top-2"
                            />
                        </div>
                        <div class="flex flex-col gap-1 overflow-y-auto" :style="'max-height: ' + maxHeight + 'px'">

                            <div v-if="!filteredItems.length" class="text-sm text-gray-400 p-2">
                                {{ $t('common.dropdownSearchNoMatch') }}
                            </div>

                            <div class="pr-1 flex flex-col gap-1">
                                <MenuItem
                                    v-for="(item, i) in filteredItems"
                                    :key="'menu-item-' + i"
                                    :disabled="modelValue == item.value"
                                >
                                    <div
                                        @click="select(item)"
                                        :class="[
                                            modelValue == item.value ? 'bg-primary-default text-white' : 'text-gray-900 hover:bg-gray-100',
                                            'group flex gap-2 w-full items-center text-left rounded-md px-2 py-2 text-sm cursor-pointer',
                                        ]"
                                    >
                                        <FontIcon
                                            v-if="item.icon"
                                            :icon="item.icon"
                                            :size="12"
                                            :class="item.iconCls ? item.iconCls : modelValue == item.value ? 'text-white' : 'text-gray-400'"
                                        />
                                        <div class="mb-[-1px]">
                                            {{ item.text }}
                                        </div>
                                    </div>
                                </MenuItem>
                            </div>

                        </div>
                    </div>

                </MenuItems>

            </transition>
        </Menu>
    </div>
</template>
<script lang="ts">
import { defineComponent, type PropType } from "vue";
import { Menu, MenuButton, MenuItems, MenuItem } from "@headlessui/vue";
import FontIcon from "@/ui/FontIcon.vue";

export type Option = {
    text: string;
    value: string;
    icon?: string;
    iconCls?: string; // Class string that will be added to the icon, i.e. text-primary-default
}

export default defineComponent({
    name: "InputDropdown",
    components: {
        Menu,
        MenuButton,
        MenuItems,
        MenuItem,
        FontIcon,
    },
    props: {
        placeholder: { type: String as PropType<string>, required: false, default: "" },
        loading: { type: Boolean as PropType<boolean>, required: false, default: false },
        modelValue: { type: String as PropType<string>, required: true },
        items: {
            type: Array as PropType<Option[]>,
            required: true,
        },
        searchable: { type: Boolean as PropType<boolean>, required: false, default: true },
        maxHeight: { type: Number as PropType<number>, required: false, default: 400 },
    },
    emits: ["update:modelValue"],
    computed: {
        filteredItems(): Option[] {
            return this.search ? this.items.filter(x => x.text.toLowerCase().includes(this.search.toLowerCase())) : this.items;
        },
        selectedItem(): Option | undefined {
            return this.items.find(x => x.value === this.modelValue);
        },
    },
    data() {
        return {
            search: "",
        };
    },
    methods: {
        select(value: Option) {
            this.$emit("update:modelValue", value.value);
        },
        menuBtnClicked() {
            this.search = "";
            // this is very annoying but we have to wait for the transition to complete before focusing
            // maybe headlessui has some way to hook into the MenuItems.Open event but couldn't find it
            setTimeout(() => {
                // @ts-expect-error 123
                this.$refs.search?.focus();
            }, 50);
        },
    },
});
</script>
