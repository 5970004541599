<template>
    <Popup
        @closed="closeAddPersonModal"
        :ignore-click-outside="true"
        width="550px"
        :title="$t('persons.addPerson')"
        title-icon="person-blue"
    >
        <div class="w-full border border-solid border-light rounded-t-lg p-5">
            <div class="grid grid-cols-2 gap-3">
                <InputGroup
                    :width="12"
                >
                    <InputLabel
                        :text="$t('common.person_info.firstName')"
                        :unique-id="'first-name'"
                        required
                    />
                    <InputText
                        v-model="givenName"
                        :unique-id="'given-name'"
                        :placeholder="'John'"
                    />
                </InputGroup>
                <InputGroup
                    :width="12"
                >
                    <InputLabel
                        :text="$t('common.person_info.lastName')"
                        :unique-id="'last-name'"
                        required
                    />
                    <InputText
                        v-model="familyName"
                        :unique-id="'family-name'"
                        :placeholder="'Doe'"
                    />

                </InputGroup>

                <InputGroup
                    :width="12"
                >
                    <InputLabel
                        :text="$t('common.person_info.dateOfBirth')"
                        :unique-id="'date-of-birth'"
                    />
                    <div class="">
                        <DatePicker
                            v-model="dateOfBirth"
                        />
                    </div>
                </InputGroup>

                <InputGroup
                    :width="12"
                >
                    <SelectBox
                        v-model="roles"
                        :label="$t('persons.role')"
                        :options="roleOptions"
                        :show-flag="false"
                        required
                    />
                </InputGroup>
            </div>
        </div>
        <div class="w-full flex justify-end gap-4 mt-5">
            <ActionButton
                theme="transparent"
                :loading="false"
                :disabled="false"
                @btn-clicked="closeAddPersonModal"
            >
                Cancel
            </ActionButton>
            <ActionButton
                theme="primary"
                :loading="false"
                :disabled="false"
                @btn-clicked.once="addPersonWithRole"
            >
                Add Person
            </ActionButton>

        </div>
    </Popup>
</template>

<script lang="ts">
import DatePicker from "@/components/DatePicker.vue";
import Popup from "@/components/Popup.vue";
import { createPerson } from "@/lib/persons/create_person";
import { createRole } from "@/lib/role/create_role";
import { RoleCode, RoleDescriptionWithoutSource } from "@/lib/role/Role";
import { defineComponent } from "vue";
import InputLabel from "@/ui/inputs/InputLabel.vue";
import InputText from "@/ui/inputs/InputText.vue";
import InputGroup from "@/ui/inputs/InputGroup.vue";
import ActionButton from "../ActionButton.vue";
import SelectBox from "@/components/SelectBox.vue";
import { PersonInfoBlock } from "@/lib/persons/person";
import { updateAsKeyRole } from "@/lib/role/update_role";

export default defineComponent({
    name: "AddPersonPopup",
    components: {
        Popup,
        DatePicker,
        InputLabel,
        InputText,
        InputGroup,
        SelectBox,
        ActionButton,
    },
    props: {},
    data() {
        return {
            givenName: "",
            familyName: "",
            email: "",
            phone: "",
            roles: [],
            dateOfBirth: "",
            RoleCode,
            selectedRole: "",
        };
    },
    emits: ["close-modal", "refreshPersons"],
    computed: {
        roleOptions(): { label: string; value: string }[] {
            const roles: { label: string; value: string }[] = [];
            for (const key in RoleCode) {
                if (RoleCode.hasOwnProperty(key)) {
                    roles.push({ label: key, value: RoleCode[key as keyof typeof RoleCode] });
                }
            }
            return roles;
        },
    },
    watch: {},
    methods: {
        closeAddPersonModal() {
            this.$emit("close-modal");
        },
        async addPersonWithRole() {
            if (!this.givenName || !this.familyName || !this.dateOfBirth || (Array.isArray(this.roles) ? !this.roles.length : !this.roles)) {
                return;
            }
            const dob = new Date(this.dateOfBirth);
            const dateObject = {
                year: dob.getFullYear(),
                month: dob.getMonth() + 1,
                day: dob.getDate(),
            };
            const options: PersonInfoBlock = {
                givenName: this.givenName,
                familyName: this.familyName,
                dateOfBirth: dateObject,
            };
            const person = await createPerson(options);
            const finalRole = Array.isArray(this.roles)
                ? this.roles.map((r) => {
                    const roleDes: RoleDescriptionWithoutSource = { key: r as unknown as RoleCode };
                    return roleDes;
                })
                : [{ key: this.roles as unknown as RoleCode }];
            const projectId = this.$route.params.id.toString();
            const role = await createRole(this.$route.params.id.toString(), person.id, finalRole);
            await updateAsKeyRole(projectId, person.id, true);
            this.$emit("refreshPersons");
            this.$emit("close-modal");
        },
    },
});
</script>
