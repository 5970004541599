<template>
    <PopupDialog
        :is-visible="true"
        :header="$t('settings.approvals.header')"
        :icon="'wand-magic-sparkles'"
        :show-cancel-button="true"
        :show-action-button="true"
        :action-text="$t('common.actions.save')"
        :action-icon="'floppy-disk'"
        :is-action-disabled="!dialog.valid || !modelChanged"
        :progress="dialog"
        @close-modal="onClose"
        @action="onSave"
    >
        <PopupDialogSplit
            is-form
            :prompt-text="promptText"
            :prompt-hint="promptHint"
            class="overflow-y-auto pr-1"
        >

            <InputGroup :width="12">
                <InputLabel
                    :text="$t('settings.approvals.keyLabel')"
                    :unique-id="'end-key'"
                    required
                />
                <InputText
                    v-model="model.key"
                    :unique-id="'end-key'"
                    :disabled="isEditMode"
                    @input="validateDialog"
                />
            </InputGroup>

            <InputGroup :width="12">
                <InputLabel
                    :text="$t('settings.approvals.nameLabel')"
                    :unique-id="'end-name'"
                    required
                />
                <InputTextLocalized
                    v-model="model.name"
                    :unique-id="'end-name'"
                    @input="validateDialog"
                    @enter="onSave"
                />
            </InputGroup>

            <InputGroup class="mt-3" :width="12">
                <div class="flex gap-3 items-center">
                    <SwitchInput v-model="model.useApproversList" />
                    <label class="m-0">{{ $t('settings.approvals.useApproversListLabel') }}</label>
                </div>
            </InputGroup>

            <div class="mt-3 w-full flex gap-3">
                <InputDropdown
                    v-model="selectedEmployee"
                    :items="employeesOptions"
                    :max-height="250"
                    :placeholder="$t('settings.approvals.addApprover')"
                    class="flex-1"
                />
                <ButtonSingle
                    :text="$t('common.actions.add')"
                    :outline="true"
                    @clicked="addApprover"
                    :disabled="selectedEmployee == ''"
                />
            </div>

            <div class="mt-3 w-full h-[300px] overflow-y-auto bg-gray-100 rounded-md text-sm">
                <div
                    v-for="(approver, index) in model.approvers"
                    :key="approver"
                    :class="{'bg-gray-200': index % 2, 'flex items-center py-2 px-3': true}"
                >
                    <div class="flex-1">
                        {{ (employees.find(x => x.uid == approver)?.givenName + ' ' + employees.find(x => x.uid == approver)?.familyName) || approver }}
                    </div>
                    <div @click="removeApprover(approver)" class="text-red-800 hover:text-red-900 cursor-pointer">
                        {{ $t('common.actions.remove') }}
                    </div>
                </div>
            </div>

        </PopupDialogSplit>
    </PopupDialog>
</template>
<script lang="ts">
import { defineComponent, PropType } from "vue";

import PopupDialog from "@/ui/PopupDialog.vue";

import { LocalizedString, LocalizedStringValidation, validateLocalizedString } from "@/lib/common/common";
import PopupDialogSplit from "@/ui/dialog/PopupDialogSplit.vue";
import { DropdownItem } from "@/ui/inputs/input_helper";
import InputGroup from "@/ui/inputs/InputGroup.vue";
import InputLabel from "@/ui/inputs/InputLabel.vue";
import InputText from "@/ui/inputs/InputText.vue";
import InputTextLocalized from "@/ui/inputs/InputTextLocalized.vue";
import { ApprovalConfig } from "@/lib/settings/settings";
import SwitchInput from "@/components/SwitchInput.vue";
import InputDropdown, { Option } from "@/ui/inputs/InputDropdown.vue";
import ButtonSingle from "@/ui/ButtonSingle.vue";
import { Employee } from "@/lib/common/identity";

export default defineComponent({
    name: "ApprovalSettingsDialog",
    components: {
        PopupDialog,
        InputGroup,
        InputLabel,
        InputText,
        InputTextLocalized,
        PopupDialogSplit,
        SwitchInput,
        InputDropdown,
        ButtonSingle,
    },
    emits: ["close-modal", "add", "edit"],
    props: {
        config: { type: Object as PropType<ApprovalConfig | null>, default: null },
    },
    data() {
        return {
            isEditMode: false,
            dialog: { valid: false, isWorking: false, statusText: "", isError: false, errorText: "" },
            model: {
                key: "",
                name: {} as LocalizedString,
                useApproversList: false,
                approvers: [] as string[],
            },
            modelStrCopy: "",
            configs: new Array<DropdownItem>(),
            promptText: "",
            promptHint: "",
            employees: [] as Employee[],
            employeesOptions: [] as Option[],
            selectedEmployee: "",
        };
    },
    computed: {
        modelChanged(): boolean {
            return JSON.stringify(this.model) !== this.modelStrCopy;
        },
    },
    methods: {

        validateDialog() {
            let valid = true;

            if (!this.model.key) {
                valid = false;
            }

            if (!validateLocalizedString(this.model.name, LocalizedStringValidation.AnyLanguage)) {
                valid = false;
            }

            this.dialog.valid = valid;
        },

        addApprover() {
            this.model.approvers.push(this.selectedEmployee);
            this.employeesOptions = this.employeesOptions.filter(x => x.value !== this.selectedEmployee);
            this.selectedEmployee = "";
            this.validateDialog();
        },

        removeApprover(uid: string) {
            this.model.approvers = this.model.approvers.filter(x => x !== uid);
            const employee = this.employees.find(x => x.uid === uid);
            if (employee) {
                this.employeesOptions.push({ text: `${employee.givenName} ${employee.familyName}`, value: employee.uid, icon: "user" });
            }
            this.sortUsers();
            this.validateDialog();
        },

        sortUsers() {
            this.employeesOptions.sort((a, b) => a.text.localeCompare(b.text));
        },

        async onSave() {
            this.dialog.isWorking = true;

            if (this.isEditMode) {
                this.$emit("edit", this.model);
            } else {
                this.$emit("add", this.model);
            }

            // The actual save happens outside of this component so we add a small delay here before
            // closing the popup to indicate to the user that the changes are being saved
            await new Promise<void>((resolve) => {
                setTimeout(() => {
                    resolve();
                }, 500);
            });

            this.onClose();
        },

        onClose() {
            this.$emit("close-modal");
        },

    },
    async mounted() {
        this.$config.settings.riskConfigs?.forEach((config) => {
            this.configs.push({
                value: config.key,
                text: config.name,
            });
        });
        if (this.config) {
            this.model = JSON.parse(JSON.stringify(this.config));
            this.promptText = this.$t("settings.approvals.promptTextEdit");
            this.promptHint = this.$t("settings.approvals.promptHintEdit");
            this.isEditMode = true;
        } else {
            this.promptText = this.$t("settings.approvals.promptTextSave");
            this.promptHint = this.$t("settings.approvals.promptHintSave");
            this.isEditMode = false;
        }

        const employees = await this.$assets.getEmployees();
        this.employees = employees;
        this.employeesOptions = employees.filter(x => !this.model.approvers.includes(x.uid)).map(x => ({ text: `${x.givenName} ${x.familyName}`, value: x.uid, icon: "user" }));
        this.sortUsers();

        this.modelStrCopy = JSON.stringify(this.model);

        this.validateDialog();
    },
});
</script>
