import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e7e24d74"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "border border-slate-300 flex flex-col rounded-lg mb-8" }
const _hoisted_2 = { class: "relative" }
const _hoisted_3 = { class: "absolute top-2/3 left-1/2 transform -translate-x-1/2 pb-4 -translate-y-1/2 text-center" }
const _hoisted_4 = { class: "text-3xl font-roboto-slab" }
const _hoisted_5 = { class: "-mt-10 px-5" }
const _hoisted_6 = { class: "flex sm:flex-col justify-around gap-2 w-full pb-5" }
const _hoisted_7 = { class: "hidden sm:block" }
const _hoisted_8 = { class: "flex gap-3 items-center justify-between" }
const _hoisted_9 = { class: "w-6 text-right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HalfDoughnutChart = _resolveComponent("HalfDoughnutChart")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_HalfDoughnutChart, {
        "chart-data": _ctx.chartData,
        "chart-options": _ctx.chartOptions
      }, null, 8, ["chart-data", "chart-options"]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.totalClient), 1),
        _createElementVNode("p", null, _toDisplayString(_ctx.$t("common.totalClients")), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.labels, (label, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: label,
            class: "flex justify-between text-sm"
          }, [
            _createElementVNode("div", _hoisted_7, _toDisplayString(label), 1),
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("div", {
                class: "w-2 h-2 font-medium rounded-full",
                style: _normalizeStyle(`background-color: ${_ctx.backgroundColor[index]}`)
              }, null, 4),
              _createElementVNode("div", _hoisted_9, _toDisplayString((Math.round(_ctx.riskCounts[index]/_ctx.totalClient*100) || 0) + "%"), 1)
            ])
          ]))
        }), 128))
      ])
    ])
  ]))
}