<template>
    <div v-if="!loading" class="amlv3-nav-routes">

        <div class="flex flex-col gap-3 text-gray-600">
            <div
                class="flex flex-col gap-3"
                v-for="route in routes"
                :key="route.name"
            >
                <div v-if="route.children?.length" class="flex gap-1 items-center pt-2">
                    <div class="h-[2px] px-[6px] bg-background"></div>
                    <div class="text-xs font-bold uppercase text-gray-400">
                        {{ $t(route.text) }}
                    </div>
                    <div class="h-[2px] px-2 bg-background flex-1"></div>
                </div>

                <div
                    @click="onRouteClicked(child)"
                    v-for="child in route.children?.flat()"
                    :key="child.name"
                    :class="'py-3 px-4 rounded-md cursor-pointer' + (isRouteActive(child) ? ' bg-primary-default text-white' : ' hover:bg-gray-100')"
                >
                    {{ $t(child.text) }}
                </div>

                <div
                    @click="onRouteClicked(route)"
                    v-if="!route.children?.length"
                    :class="'py-3 px-4 rounded-md cursor-pointer' + (isRouteActive(route) ? ' bg-primary-default text-white' : ' hover:bg-gray-100')"
                >
                    {{ $t(route.text) }}
                </div>

            </div>
        </div>

    </div>
</template>
<script lang="ts">
import { defineComponent, PropType } from "vue";
import { RouteDefinition } from "../Nav.vue";
import FontIcon from "@/ui/FontIcon.vue";

export default defineComponent({
    name: "ExperimentalNavRoutes",
    components: {
        FontIcon,
    },
    props: {
        routes: { type: Array as PropType<RouteDefinition[]>, required: true },
    },
    data() {
        return {
            routesParsed: [] as RouteDefinition[],
            loading: true,
        };
    },
    watch: {
    },
    methods: {
        isRouteActive(route: RouteDefinition) {
            if (route.active.includes(this.$route.path)) {
                return true;
            }

            for (const str of route.active) {
                if (this.$route.path.includes(str.replace("*", ""))) {
                    return true;
                }
            }

            for (const child of route.children) {
                for (const str of child.active) {
                    if (this.$route.path.includes(str.replace("*", ""))) {
                        return true;
                    }
                }
            }

            return false;
        },

        onRouteClicked(route: RouteDefinition) {
            if (route.children && route.children.length > 0) {
                route.expanded = !route.expanded;
            }
            this.$router.push({ path: route.path });
        },

    },
    async mounted() {
        await this.$router.isReady();
        this.loading = false;
    },
});
</script>
