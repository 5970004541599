<template>
    <Doughnut
        ref="my-chart-id"
        :options="doughnutOptions"
        :data="doughnutData "
    />
</template>

<script lang="ts">
import { Doughnut } from "vue-chartjs";
import { defineComponent } from "vue";
import { ArcElement, BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip } from "chart.js";

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, ArcElement);

export default defineComponent({
    name: "HalfDoughnutChart",
    components: { Doughnut },
    props: {
        chartData: {
            type: Object,
            required: true,
        },
        chartOptions: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {};
    },
    computed: {
        myStyles() {
            return {
                height: "300px",
                position: "relative",
            };
        },
        doughnutData(): any {
            return this.chartData;
        },
        doughnutOptions(): any {
            return this.chartOptions;
        },
    },
});
</script>
